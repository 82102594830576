<template>
  <q-form @submit="handleSubmit">
    <div
      v-if="loadedMessages.length <= 0"
      class="row items-center justify-center"
      :style="`text-align: center; height: ${chatHeight}vh;`"
    >
      <span v-if="messagesLoading">
        <q-spinner
          color="light-blue-9"
          size="3rem"
          class="q-mr-md"
        />
      </span>

      <span v-else>
        {{ $t('No messages!') }}
      </span>
    </div>

    <div
      v-else
      class="q-mb-sm"
    >
      <div
        :class="`q-px-sm q-mb-${priorityMessages.length > 0 ? 'sm' : 'none'} styled-scrollbar`"
        :style="`max-height: 156px; overflow: auto;`"
      >
        <chat-pinned-item
          v-for="item in priorityMessages"
          :key="item.id"
          :item="item"
          :options="options"
          class="border-bottom pointer card--clickable card--clickable-no-scale"
          @click="scrollToElement"
          @unpin="handleUnpin"
        />
      </div>

      <div
        class="q-px-md styled-scrollbar"
        :style="`height: ${chatHeight}vh; overflow: auto;`"
      >
        <chat-item
          v-for="item in loadedMessages"
          :ref="getMessageRefName(item)"
          :key="item.id"
          :item="item"
          :sender="userData"
          :options="options"
          @pin="handlePin"
          @delete="handleDelete"
          @edit="handleEdit"
        />
      </div>
    </div>

    <div class="border-top q-pt-xs q-px-md">
      <div
        v-if="message"
        class="q-pt-xs"
      >
        <strong>{{ $t('Edit message') + ': ' + message.id }}</strong>
      </div>

      <div class="row items-center">
        <div class="col">
          <q-input
            ref="commentInput"
            v-model="comment"
          />
        </div>

        <q-btn
          icon="send"
          color="light-blue-9"
          flat
          round
          dense
          :disable="isSubmited"
          @click="handleSubmit"
        />

        <q-btn
          icon="delete"
          color="negative"
          flat
          round
          dense
          @click="handleClear"
        />
      </div>
    </div>
  </q-form>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ChatItem from './ChatItem'
import ChatPinnedItem from './ChatPinnedItem'

// Helpers
import { difference } from '../../helpers/request-helpers'

export default {
  name: 'Chat',
  components: {
    ChatItem,
    ChatPinnedItem
  },
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    height: {
      type: Number,
      default () {
        return 100
      }
    },
    entities: {
      type: Array,
      required: true
    },
    isFullscreen: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      comment: '',
      isSubmited: false,
      message: null,
      loadedMessages: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      priorityMessages: [],
      onFocusTimeout: null,
      entityObject: {}
    }
  },
  computed: {
    ...mapGetters([
      'messages',
      'messagesPage',
      'allMessages',
      'messagesLoading'
    ]),
    chatHeight () {
      let value = this.isFullscreen
        ? 22
        : 25

      if (this.priorityMessages.length > 0) {
        const length = this.priorityMessages.length > 3
          ? 3
          : this.priorityMessages.length

        value += length * 100 * (52 / window.innerHeight)
      }

      return this.height - value
    }
  },
  watch: {
    entities (value) {
      this.entityObject = this.createEntityObject(value)
    },
    allMessages (value) {
      const message = value[value.length - 1]

      this.addMessage(message)
    },
    messages () {
      this.handleChange('messages')

      // Render is Async, which mean that ref to element is created on next rendering
      this.$nextTick(() => {
        this.scrollToElement(this.loadedMessages[this.loadedMessages.length - 1])
      })
    }
  },
  mounted () {
    this.entityObject = this.createEntityObject(this.entities)
    this.loadNextItems('', 1, 'messages')
  },
  unmounted () {
    if (this.onFocusTimeout) {
      clearTimeout(this.onFocusTimeout)
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'setMessages'
    ]),
    ...mapActions([
      'loadMessages'
    ]),
    getDiff (message, options = {}) {
      if (!options.handler) {
        const diff = difference(message.entity || {}, this.entityObject)
        const secondDiff = difference(this.entityObject, message.entity || {})

        return { ...diff, ...secondDiff }
      }

      if (message.entity[options.handler] === this.entityObject[options.handler]) {
        return {}
      }

      return message.entity
    },
    getIsDifferentEntity (message, options = {}) {
      return Object.keys(this.getDiff(message, options)).length > 0
    },
    addMessage (message) {
      if (this.getIsDifferentEntity(message, this.options)) {
        return
      }

      let isExist = false

      const messages = this.loadedMessages.map(x => {
        if (x.id === message.id) {
          isExist = true

          return {
            ...x,
            comment: message.comment,
            priority: message.priority
          }
        }

        return x
      })

      if (isExist) {
        this.loadedMessages = messages
      } else {
        const newMessage = {
          ...message,
          _embedded: {
            author: message._embedded && message._embedded.author
              ? message._embedded.author
              : this.findAuthor(message.author)
          }
        }

        this.loadedMessages = [...messages, newMessage]
      }

      // Render is Async, which mean that ref to element is created on next rendering
      this.$nextTick(() => {
        this.scrollToElement(message)
      })

      this.priorityMessages = this.loadedMessages.filter(({ priority }) => Number(priority) > 0)
    },
    focusOnLastMessage () {
      if (this.loadedMessages.length <= 0) {
        return null
      }

      const item = this.loadedMessages[this.loadedMessages.length - 1]

      this.onFocusTimeout = setTimeout(() => {
        this.scrollToElement(item)
      }, 1500)

      return item
    },
    findAuthor (id) {
      const message = this.loadedMessages.find(x => `${x._embedded.author.id}` === `${id}`)

      return message
        ? message._embedded.author
        : { id }
    },
    createEntityObject (entities) {
      return entities.reduce((acc, { entityClass, entity }) => {
        return {
          ...acc,
          [entityClass]: entity
        }
      }, {})
    },
    handleClear () {
      this.message = null
      this.comment = ''
    },
    handlePin (item) {
      return this.$service.message.save({ priority: 1 }, item.id)
        .then(item => {
          this.priorityMessages = [item, ...this.priorityMessages]
        })
    },
    handleDelete (item) {
      return this.$service.message.save({ state: 'inactive' }, item.id)
        .then(() => {
          this.loadedMessages = this.loadedMessages.filter(({ id }) => id !== item.id)
          this.priorityMessages = this.priorityMessages.filter(({ id }) => id !== item.id)
        })
    },
    handleEdit (item) {
      this.message = item
      this.comment = item.comment
      this.$refs.commentInput.focus()
    },
    handleUnpin (item) {
      return this.$service.message.save({ priority: 0 }, item.id)
        .then(item => {
          this.priorityMessages = this.priorityMessages.filter(({ id }) => id !== item.id)
          this.loadedMessages = this.loadedMessages.map(x => {
            return x.id === item.id
              ? item
              : x
          })
        })
    },
    scrollToElement (item) {
      if (!item) {
        return
      }

      this.$refs[this.getMessageRefName(item)][0].$el.scrollIntoView({ behavior: 'smooth' })
    },
    getMessageRefName (item) {
      return `message-${item.id}`
    },
    handleChange (type) {
      const types = {
        messages: {
          getter: 'messages',
          page: 'messagesPage',
          loadedValue: 'loadedMessages',
          additionalAction: (messages) => {
            const items = messages.reduce((acc, message) => {
              if (message.state === 'inactive') {
                return acc
              }

              if (!this.getIsDifferentEntity(message)) {
                acc.reduced.push(message)
              }

              if (message.priority > 0) {
                acc.priority.push(message)
              }

              return acc
            }, { priority: [], reduced: [] })

            this.priorityMessages = items.priority
            this.loadedMessages = items.reduced
          }
        }
      }

      const current = types[type]

      if (!current) {
        this.addErrorNotification('Unable to handle filter change!')
        return
      }

      if (this[current.page] <= 1) {
        this[current.loadedValue] = this[current.getter]
      } else {
        this[current.loadedValue] = [
          ...this[current.loadedValue],
          ...this[current.getter]
        ]
      }

      if (current.additionalAction) {
        current.additionalAction(this[current.loadedValue])
      }
    },
    loadNextItems (search, page, loader) {
      const loaders = {
        messages: this.loadMessages
      }

      if (typeof loaders[loader] !== 'function') {
        this.addErrorNotification('Items loader is unknown!')
        return
      }

      const query = this.createQuery({ search, page }, loader)

      return loaders[loader](query)
    },
    createQuery (params, type) {
      const query = {
        per_page: 25,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ],
        ...params
      }

      if (type === 'messages') {
        query.per_page = 250

        query['order-by'] = [
          {
            type: 'field',
            field: 'created',
            direction: 'asc'
          }
        ]

        // let shopId

        const entitiesFilter = this.entities.reduce((acc, { entityClass, entity }) => {
          // if (entityClass === 'Orderadmin\\Products\\Entity\\Shop') {
          //   shopId = entity
          // }

          acc.push({
            type: 'jsonboperator',
            field: 'entity',
            subtype: 'eq',
            subfield: entityClass,
            value: entity
          })

          return acc
        }, [])

        // if (shopId) {
        //   query.filter.push({
        //     type: 'andx',
        //     conditions: [
        //       {
        //         type: 'like',
        //         field: 'entity::text',
        //         value: `%Shop": ${shopId}%`
        //       },
        //       { field: 'priority', type: 'gte', value: '0' },
        //       { type: 'eq', field: 'state', value: 'active' }
        //     ],
        //     where: 'and'
        //   })
        // }

        query.filter = [
          ...query.filter,
          ...entitiesFilter
        ]
      }

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
          ? '*'
          : ':*'
      }

      return query
    },
    handleMessageEdit () {
      this.isSubmited = true

      return this.$service.message.save({ comment: this.comment }, this.message.id)
        .then(item => {
          this.message = null
          this.comment = ''

          this.loadedMessages = this.loadedMessages.map(x => {
            return x.id === item.id
              ? item
              : x
          })

          if (item.priority > 0) {
            this.priorityMessages = this.priorityMessages.map(x => {
              return x.id === item.id
                ? item
                : x
            })
          }
        })
        .finally(_ => {
          this.isSubmited = false
        })
    },
    handleSubmit (e) {
      e.preventDefault()

      if (this.comment.length <= 0 || this.isSubmited) {
        return
      }

      if (this.message) {
        return this.handleMessageEdit()
      }

      this.isSubmited = true

      const data = {
        extId: Math.floor(Math.random() * (9999999999) + 1),
        type: 'public',
        entity: this.entityObject,
        title: 'title',
        comment: this.comment,
        priority: 0,
        author: this.userData.id,
        attachments: []
      }

      return this.$service.message.save(data)
        .then(message => {
          this.comment = ''
          this.addMessage(message)
        })
        .finally(() => {
          this.isSubmited = false
        })
    }
  }
}
</script>
